<template>
  <aside
    v-if="staffSidenavLoadingComplete"
    class="side-nav sidebar-container hide-for-small hide-for-medium"
  >
    <div class="sidebar">
      <search-component></search-component>
      <side-nav-staff
        :show-checklist="showChecklist"
        :course-staff-nav-items="staffOrAdminNav"
      ></side-nav-staff>
    </div>
  </aside>
  <!-- student/observer sidenav -->
  <aside
    v-else-if="studentSidenavLoadingComplete"
    class="sidebar-container hide-for-small hide-for-medium"
  >
    <side-nav-student-observer />
  </aside>

  <!-- mobile offscreen sidebar, previously attached to top_bar/header -->
  <aside class="offscreen-sidebar">
    <div class="sidebar">
      <template v-if="studentSidenavLoadingComplete">
        <a :href="allOverview" aria-label="Return to your GradeCraft dashboard">
          Return to My Dashboard
        </a>
        <div class="course-site">
          <p class="course">
            {{ courseFullTitle }}
          </p>
          <p class="course-name">
            {{ currentCourse.name }}
          </p>
        </div>
      </template>
      <div class="mobile-menu-content">
        <template v-if="courseLoadingComplete">
          <side-nav-staff
            v-if="staffSidenavLoadingComplete"
            :show-checklist="showChecklist"
            :course-staff-nav-items="staffOrAdminNav"
          ></side-nav-staff>
          <side-nav-student-observer v-else />
          <course-info :header="false" />
        </template>
        <account-menu />
      </div>
    </div>
  </aside>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import AccountMenu from "./AccountMenu.vue";
import CourseInfo from "./CourseInfo.vue";
import SearchComponent from "./SearchComponent.vue";
import SideNavStaff from "./SideNavStaff.vue";
import SideNavStudentObserver from "./SideNavStudentObserver.vue";
import { capitalizeFirstLetter } from "@/courses/utilities/base.js";
import { overviewUrl } from "@/courses/urls.js";
export default {
  name: "SideNav",
  components: {
    AccountMenu,
    CourseInfo,
    SearchComponent,
    SideNavStaff,
    SideNavStudentObserver,
  },
  props: {
    sidenavClass: {
      type: String,
      default: "is-closed",
    },
    sessionCourseId: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      active: false,
      termsLoaded: false,
      showChecklist: false,
      navCountsLoaded: false,
    };
  },
  computed: {
    ...mapState("navigation", ["courseId"]),
    // mobile menu can't be accessed unless header is loaded
    // therefore no need to get headerData again
    ...mapState("course", ["currentCourse"]),
    ...mapGetters("navigation", ["courseStaffNavItems"]),
    ...mapGetters(["isAdmin", "isStaff"]),
    allOverview() {
      return overviewUrl.stringify();
    },
    staffOrAdminNav() {
      return this.isAdmin
        ? this.courseStaffNavItems
        : this.courseStaffNavItems.filter(
            (navSection) => navSection.name != "Admin",
          );
    },
    courseFullTitle() {
      let semester = this.currentCourse.semester
        ? capitalizeFirstLetter(this.currentCourse.semester)
        : "";
      return `${this.currentCourse.course_number} ${semester} ${this.currentCourse.year}`;
    },
    courseLoadingComplete() {
      return this.courseId && this.termsLoaded;
    },
    studentSidenavLoadingComplete() {
      return !this.isStaff && this.courseLoadingComplete;
    },
    staffSidenavLoadingComplete() {
      return this.isStaff && this.courseLoadingComplete;
    },
  },
  created() {
    this.getCurrentUser().then(() => {
      if (this.courseId && !isNaN(this.courseId)) {
        this.loadCourseData(this.courseId);
      } else if (this.sessionCourseId) {
        // courseId was set in navigation through urlpattern
        // which isn't possible for urls that don't have course_id but are still in course page layout
        // so default to course_id in session
        this.setCourseId(this.sessionCourseId);
        this.loadCourseData(this.courseId);
      }
    });
  },
  methods: {
    ...mapMutations("navigation", ["setCourseId", "setNavOpenDefaults"]),
    ...mapActions("course", ["getCurrentCourse"]),
    ...mapActions("navigation", [
      "loadNavigationCounts",
      "getCourseCreationProgress",
    ]),
    ...mapActions(["getCurrentUser"]),
    loadCourseData(courseId) {
      this.getCurrentCourse(courseId).then(() => {
        if (this.isStaff) {
          if (!this.currentCourse.published) {
            this.getCourseCreationProgress().then(() => {
              this.showChecklist = true;
            });
          }
          this.setNavOpenDefaults();
          this.loadNavigationCounts(this.courseId).then(() => {
            this.navCountsLoaded = true;
          });
        }
        this.termsLoaded = true;
      });
    },
  },
  watch: {
    staffSidenavLoadingComplete(newValue) {
      if (newValue) {
        this.emitter.emit("sidenav-loading-complete");
      }
    },
    studentSidenavLoadingComplete(newValue) {
      if (newValue) {
        this.emitter.emit("sidenav-loading-complete");
      }
    },
  },
};
</script>
