<script setup>
import { isNil } from "lodash";
import { computed } from "vue";
import VueMultiselect from "vue-multiselect";

const props = defineProps({
  context: {
    type: Object,
    required: true,
  },
});

const options = computed(() => props.context.attrs.options);
const multiple = props.context.multiple;
const loading = props.context.loading;
const closeOnSelect = props.context.closeOnSelect || multiple === false;
const searchable = props.context.searchable;
const placeholder = props.context.placeholder;
const allowEmpty = props.context.allowEmpty;

const toOption = (value) =>
  options.value.find((option) => option.value === value) || value;
const toValue = ({ value }) => value;

const inputValueToFormKitValue = (inputValue) => {
  if (isNil(inputValue)) return inputValue;
  return multiple ? inputValue.map(toValue) : toValue(inputValue);
};

const formKitValueToInputValue = (formKitValue) => {
  if (isNil(formKitValue)) return formKitValue;
  return multiple ? formKitValue.map(toOption) : toOption(formKitValue);
};

const value = computed({
  get: () => {
    const formKitSelectedValues = props.context._value;
    return formKitValueToInputValue(formKitSelectedValues);
  },
  set: (vmsSelectedOptions) => {
    const selectedValues = inputValueToFormKitValue(vmsSelectedOptions);
    props.context.node.input(selectedValues);
  },
});
</script>

<template>
  <vue-multiselect
    v-model="value"
    :show-labels="true"
    :multiple="multiple"
    :searchable="searchable"
    :close-on-select="closeOnSelect"
    :options="options"
    :disabled="context.disabled"
    :loading="loading"
    :name="context.node.name"
    :id="context.id"
    :placeholder="placeholder"
    :allow-empty="allowEmpty"
    label="label"
    track-by="value"
    open-direction="auto"
    v-bind="$attrs"
    class="vue-multiselect"
  />
</template>
