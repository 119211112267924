import { createInput } from "@formkit/vue";
import CustomRichText from "@/courses/components/inputs/CustomRichText.vue";
import CustomDatePicker from "@/courses/components/inputs/CustomDatePicker.vue";
import FormKitMultiSelect from "@/courses/components/inputs/FormKitMultiSelect.vue";
import CustomSingleFileInput from "@/courses/components/inputs/CustomSingleFileInput.vue";

const isRequired = (node) =>
  node.props.parsedRules.some((rule) => rule.name === "required");

const isCheckboxAndRadioMultiple = (node) =>
  (node.props.type === "checkbox" || node.props.type === "radio") &&
  node.props.options;

const rootClasses = (section, node) => {
  const isMultiOption = isCheckboxAndRadioMultiple(node);
  const required =
    (isMultiOption ? section === "legend" : section === "label") &&
    isRequired(node);
  return {
    [`formkit-${section}`]: true,
    required,
  };
};

export default {
  inputs: {
    DatePicker: createInput(CustomDatePicker),
    RichText: createInput(CustomRichText),
    FormKitMultiSelect: createInput(FormKitMultiSelect, {
      props: [
        "multiple",
        "closeOnSelect",
        "searchable",
        "loading",
        "placeholder",
        "allowEmpty",
      ],
    }),
    SingleFileInput: createInput(CustomSingleFileInput, {
      props: ["accept"],
    }),
  },
  config: {
    rootClasses,
  },
};
