import { apiClient } from "@/courses/services/GenericService.js";
import { apiResponseToDataDataItem } from "@/courses/utilities/apiRequestHelpers.js";

import { courseCreationUrl, getNavigationCountsUrl } from "@/courses/urls.js";

export const getCourseCreationChecklist = async () => {
  const { data } = await apiClient.get(courseCreationUrl.stringify());
  return apiResponseToDataDataItem(data);
};

export const updateCourseCreationChecklist = async (item) => {
  const params = { course_creation: { [item.name]: item["done"] } };
  const { data } = await apiClient.put(courseCreationUrl.stringify(), params);
  return apiResponseToDataDataItem(data);
};

export const getNavigationCounts = async (courseId) => {
  const { data } = await apiClient.get(
    getNavigationCountsUrl.stringify({ courseId }),
  );
  return data;
};
