import { DateTime } from "luxon";

export const formatDatesMixin = {
  methods: {
    notEmpty: (obj) => Object.keys(obj).length > 0,
    shortDate: (isoTimestamp) => {
      const dt = DateTime.fromISO(isoTimestamp);
      return dt.toLocaleString(DateTime.DATE_SHORT);
    },
    shortDateTime: (isoTimestamp) => {
      const dt = DateTime.fromISO(isoTimestamp);
      return dt.toLocaleString(DateTime.DATETIME_SHORT);
    },
    monthYear: (isoTimestamp) => {
      const dt = DateTime.fromISO(isoTimestamp);
      return dt.toLocaleString({ month: "long", year: "numeric" });
    },
    fullDate: (isoTimestamp) => {
      const dt = DateTime.fromISO(isoTimestamp);
      return dt.toLocaleString(DateTime.DATE_FULL);
    },
    time: (isoTimestamp) => {
      const dt = DateTime.fromISO(isoTimestamp);
      return dt.toLocaleString(DateTime.TIME_SIMPLE);
    },
    dateAtTime: (isoTimestamp) => {
      const dt = DateTime.fromISO(isoTimestamp);
      // Monday, April 3 2023 @ 3:39 PM
      return dt.toFormat("cccc, LLLL d y @ t");
    },
    sortableTimestamp: (isoTimestamp) => {
      const dt = DateTime.fromISO(isoTimestamp);
      return dt.toFormat("X");
    },
    shortDateAtTime: (isoTimestamp) => {
      const dt = DateTime.fromISO(isoTimestamp);
      // Mon, April 3 2023 3:39 PM EDT
      return dt.toFormat("ccc, LLLL d y t ZZZZ");
    },
  },
};
