const DEFAULT_LOCALE = "en-US";

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatDateValue(isoDateString) {
  // date inputs expect a format of '%Y-%m-%d', but the db provides UTC iso strings
  // so we generate a new iso date string, then shorten it to match the required format
  const MS_PER_MINUTE = 60000;
  let date = isoDateString ? new Date(isoDateString) : new Date();
  let offset = date.getTimezoneOffset();
  let adjustedDate = new Date(date - offset * MS_PER_MINUTE);
  return adjustedDate.toISOString().slice(0, 10);
}

export function formatDateTimeValue(isoDateString) {
  const MS_PER_MINUTE = 60000;
  let date = isoDateString ? new Date(isoDateString) : new Date();
  let offset = date.getTimezoneOffset();
  let adjustedDate = new Date(date - offset * MS_PER_MINUTE);
  return adjustedDate.toISOString().slice(0, 19);
}

export function formatDateString(dateString) {
  let date = new Date(dateString);
  const options = { year: "numeric", month: "short", day: "numeric" };
  const locale = navigator.languages[0] || DEFAULT_LOCALE;
  return date.toLocaleDateString(locale, options);
}

export function createNumericDateString(dateString) {
  let date = new Date(dateString);
  const options = { year: "2-digit", month: "numeric", day: "numeric" };
  const locale = navigator.languages[0] || DEFAULT_LOCALE;
  return date.toLocaleDateString(locale, options);
}

export function pluralize(noun, count) {
  let lastCharacter = noun.toLowerCase()[noun.length - 1];
  let pluralNoun =
    lastCharacter === "y" ? noun.replace(/.$/, "ies") : `${noun}s`;
  return count === 1 ? noun : pluralNoun;
}

export function formatNum(num) {
  return Number(num).toLocaleString();
}

export function leadingZero(number) {
  let zero = Number(number) < 10 ? "0" : "";
  return `${zero}${number}`;
}

export function convertToTwelveHour(number) {
  return number % 12 || 12;
}

export function amPm(number) {
  return number <= 12 ? "AM" : "PM";
}

export function yesNo(boolean) {
  return boolean ? "Yes" : "No";
}

export function getSaveOrUpdateEventName(editing) {
  return editing ? "update-instance" : "save-instance";
}

export function scrollToTop() {
  window.scrollTo(0, 0);
}

export function objectHasKey(object, key) {
  return Object.prototype.hasOwnProperty.call(object, key);
}

export const toOptions = (items, getValue, getLabel) => {
  const choices = items.map((choice) => ({
    value: getValue(choice),
    label: getLabel(choice),
  }));
  return [{ label: "-----------", value: null }, ...choices];
};
