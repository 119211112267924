<template>
  <div class="gc-logo" :class="logoClass">
    <a
      id="header_link"
      class="gc-logo__link"
      :href="overviewUrl"
      aria-label="Return to your GradeCraft dashboard"
    ></a>
  </div>
</template>

<script>
import { overviewUrl } from "@/courses/urls.js";
export default {
  name: "TheLogo",
  props: {
    iconOnly: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    overviewUrl() {
      return overviewUrl.stringify();
    },
    logoClass() {
      return this.iconOnly ? "" : "gc-logo--full--large-screen-only";
    },
  },
};
</script>
