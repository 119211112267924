<template>
  <div>
    <header-component v-if="doneLoading" :impersonating="impersonating" />
    <slot></slot>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import HeaderComponent from "@/courses/components/navigation/HeaderComponent.vue";

export default {
  name: "BaseComponent",
  components: {
    HeaderComponent,
  },
  props: {
    courseId: {
      type: String,
      required: false,
      default: null,
    },
    impersonating: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      doneLoading: false,
    };
  },
  methods: {
    ...mapActions(["getCurrentUser"]),
    ...mapActions("course", [
      "getHeaderData",
      "getCurrentCourse",
      "getAnnouncements",
    ]),
    ...mapMutations("course", ["setCourseId"]),
  },
  computed: {
    ...mapState("course", ["currentCourse"]),
  },
  created() {
    if (this.courseId) {
      Promise.allSettled([
        this.getCurrentUser(),
        this.setCourseId(this.courseId),
        this.getHeaderData(this.courseId),
        this.getCurrentCourse(this.courseId),
        this.getAnnouncements(this.courseId),
      ]).then(() => {
        this.doneLoading = true;
      });
    } else {
      this.getCurrentUser().then(() => (this.doneLoading = true));
    }
  },
};
</script>
