import Transform from "@/courses/utilities/transformAPIResponse.js";
import {
  apiSerializer,
  mediaHeader,
  requestHeader,
} from "@/courses/services/GenericService.js";

import {
  attendanceListCreateAPIUrl,
  getAssignmentAPIUrl,
  courseAttendanceSetupUrl,
  attendanceDeleteAllAPIUrl,
  attendanceUpdateDestroyAPIUrl,
} from "@/courses/urls.js";

export const attendanceModule = {
  namespaced: true,
  state: {
    attendanceEvents: [],
    configuredEvents: [],
    weekdays: {
      0: {
        label: "Sunday",
        selected: false,
        times: [{ openAt: null, dueAt: null }],
      },
      1: {
        label: "Monday",
        selected: false,
        times: [{ openAt: null, dueAt: null }],
      },
      2: {
        label: "Tuesday",
        selected: false,
        times: [{ openAt: null, dueAt: null }],
      },
      3: {
        label: "Wednesday",
        selected: false,
        times: [{ openAt: null, dueAt: null }],
      },
      4: {
        label: "Thursday",
        selected: false,
        times: [{ openAt: null, dueAt: null }],
      },
      5: {
        label: "Friday",
        selected: false,
        times: [{ openAt: null, dueAt: null }],
      },
      6: {
        label: "Saturday",
        selected: false,
        times: [{ openAt: null, dueAt: null }],
      },
    },
  },
  actions: {
    getAttendanceEvents: async function ({ commit }) {
      const url = attendanceListCreateAPIUrl.stringify();
      const resp = await fetch(url);
      if (!resp.ok) {
        let failMessage = "There was an error retrieving attendance events.";
        commit("addErrorAlertMessage", failMessage, { root: true });
        return;
      }
      const json = await resp.json();
      const attendance = Transform.apiResponseToData(json);
      commit("setAttendanceEvents", attendance);
    },
    deleteAllAttendance: async function ({ commit }) {
      const url = attendanceDeleteAllAPIUrl.stringify();
      await fetch(url, {
        method: "DELETE",
        headers: requestHeader,
        credentials: "same-origin",
      })
        .then((response) => {
          if (response.ok) {
            commit("setAttendanceEvents", []);
          }
        })
        .then(() => {
          let successMessage = "Successfully deleted all attendance";
          commit("addSuccessAlertMessage", successMessage, { root: true });
        })
        .catch(() => {
          let failMessage = "Unable to delete all attendance events.";
          commit("addErrorAlertMessage", failMessage, { root: true });
        });
    },
    deleteAttendanceEvent: async function (
      { commit, state },
      { eventId, courseId },
    ) {
      const url = attendanceUpdateDestroyAPIUrl.stringify({ eventId: eventId });
      const resp = await fetch(url, {
        method: "DELETE",
        headers: requestHeader,
        credentials: "same-origin",
      });
      if (!resp.ok) {
        let failMessage = "Unable to delete the attendance event.";
        commit("addErrorAlertMessage", failMessage, { root: true });
        return;
      }
      let successMessage = "Successfully deleted the attendance event.";
      commit("addSuccessAlertMessage", successMessage, { root: true });
      if (state.attendanceEvents.length == 0) {
        window.location.href = courseAttendanceSetupUrl.stringify({
          courseId: courseId,
        });
      }
    },
    createAttendanceEvent: async function ({ commit }, event) {
      const url = attendanceListCreateAPIUrl.stringify();
      const resp = await fetch(url, {
        method: "POST",
        headers: requestHeader,
        credentials: "same-origin",
        body: JSON.stringify({ assignment: apiSerializer(event) }),
      });
      if (!resp.ok) {
        let failMessage = "Unable to create the attendance event.";
        commit("addErrorAlertMessage", failMessage, { root: true });
        return;
      }
      let successMessage = "Successfully created attendance.";
      commit("addSuccessAlertMessage", successMessage, { root: true });
    },
    updateAttendanceEvent: async function ({ commit }, event) {
      const url = attendanceUpdateDestroyAPIUrl.stringify({
        eventId: event.id,
      });
      const resp = await fetch(url, {
        method: "PUT",
        headers: requestHeader,
        credentials: "same-origin",
        body: JSON.stringify({ assignment: apiSerializer(event) }),
      });
      if (!resp.ok) {
        let failMessage = "Unable to update attendance.";
        commit("addErrorAlertMessage", failMessage, { root: true });
        return;
      }
      return;
    },
    postMedia: async function ({ dispatch, commit }, payload) {
      const mediaParams = new FormData();
      mediaParams.append("assignment[media]", payload.file);
      const url = getAssignmentAPIUrl.stringify({
        assignmentId: payload.event.id,
      });
      const resp = await fetch(url, {
        method: "PUT",
        headers: mediaHeader,
        body: mediaParams,
      });
      if (!resp.ok) {
        let failMessage = "There was an error saving your media.";
        commit("addErrorAlertMessage", failMessage, { root: true });
        return;
      }
      let successMessage = "Media successfully uploaded.";
      commit("addSuccessAlertMessage", successMessage, { root: true });
      dispatch("getAttendanceEvents");
    },
    postRemoveMedia: async function ({ dispatch, commit }, event) {
      const url = getAssignmentAPIUrl.stringify({ assignmentId: event.id });
      const resp = await fetch(url, {
        method: "PUT",
        headers: requestHeader,
        credentials: "same-origin",
        body: JSON.stringify({ assignment: event }),
      });
      if (!resp.ok) {
        let failMessage = "There was an error removing the media.";
        commit("addErrorAlertMessage", failMessage, { root: true });
        return;
      }
      let successMessage = "Media successfully removed.";
      commit("addSuccessAlertMessage", successMessage, { root: true });
      dispatch("getAttendanceEvents");
    },
  },
  mutations: {
    setAttendanceEvents(state, attendanceEvents) {
      state.attendanceEvents = attendanceEvents.sort((a, b) =>
        a.openAt > b.openAt ? 1 : -1,
      );
    },
    addConfiguredEvent(state, event) {
      state.configuredEvents.push(event);
    },
    removeConfiguredEvent(state, event) {
      if (confirm("Are you sure you want to delete this attendance event?")) {
        state.configuredEvents = state.configuredEvents.filter(
          (existing) => existing.position != event.position,
        );
      }
    },
    clearConfiguredEvents(state) {
      state.configuredEvents = [];
    },
  },
};
