import {
  apiClient,
  apiSerializer,
  apiDeserializer,
} from "@/courses/services/GenericService.js";

import { coursePointsPlannerAPIUrls } from "@/courses/urls.js";

const deserializeAssignmentType = ({
  id,
  description,
  has_max_points,
  max_points,
  name,
  position,
  student_weightable,
  total_points_earned,
  total_points_predicted,
  weight,
  assignments,
}) => ({
  id,
  description,
  hasMaxPoints: has_max_points,
  maxPoints: max_points,
  name,
  position,
  studentWeightable: student_weightable,
  totalPointsEarned: total_points_earned,
  totalPointsPredicted: total_points_predicted,
  weight: apiDeserializer(weight),
  assignments: assignments.map(deserializeAssignment),
});

const deserializeAssignment = ({
  id,
  course_id,
  description,
  due_at,
  full_points,
  grade,
  has_score_level,
  has_submission,
  has_threshold_points,
  is_a_condition,
  is_accepting_submissions,
  is_closed_without_submission,
  is_due_in_future,
  is_earned_by_group,
  is_late,
  is_locked,
  is_rubric_graded,
  is_unlockable,
  name,
  pass_fail,
  position,
  predicted_earned_grade,
  prediction_excluded,
  required,
  score_level_options,
  threshold_points,
  unlock_conditions,
  unlock_keys,
  unlocked_conditions,
  visible_when_locked,
  visible,
  weight,
}) => ({
  id,
  courseId: course_id,
  description,
  dueAt: due_at,
  fullPoints: full_points,
  grade: grade !== null ? apiDeserializer(grade) : grade,
  hasScoreLevel: has_score_level,
  hasSubmission: has_submission,
  hasThresholdPoints: has_threshold_points,
  isCondition: is_a_condition,
  isAcceptingSubmissions: is_accepting_submissions,
  isClosedWithoutSubmission: is_closed_without_submission,
  isDueInFuture: is_due_in_future,
  isEarnedByGroup: is_earned_by_group,
  isLate: is_late,
  isLocked: is_locked,
  isRubricGraded: is_rubric_graded,
  isUnlockable: is_unlockable,
  name,
  passFail: pass_fail,
  position,
  predictedEarnedGrade:
    predicted_earned_grade !== null
      ? apiDeserializer(predicted_earned_grade)
      : predicted_earned_grade,
  predictionExcluded: prediction_excluded,
  required,
  scoreLevelOptions: score_level_options,
  thresholdPoints: threshold_points,
  unlockConditions: unlock_conditions,
  unlockKeys: unlock_keys,
  unlockedConditions: unlocked_conditions,
  visibleWhenLocked: visible_when_locked,
  visible,
  weight,
});

const deserializeBadge = ({
  id,
  name,
  course_id,
  description,
  full_points,
  icon,
  can_earn_multiple_times,
  position,
  visible,
  visible_when_locked,
  show_name_when_locked,
  show_points_when_locked,
  show_description_when_locked,
  student_awardable,
  predicted_earned_badge,
  earned_badges,
  is_unlockable,
  is_locked,
  unlock_conditions,
  unlock_keys,
  unlocked_conditions,
}) => ({
  id,
  name,
  courseId: course_id,
  description,
  fullPoints: full_points,
  icon,
  canEarnMultipleTimes: can_earn_multiple_times,
  position,
  visible,
  visibleWhenLocked: visible_when_locked,
  showWhenWhenLocked: show_name_when_locked,
  showPointsWhenLocked: show_points_when_locked,
  showDescriptionWhenLocked: show_description_when_locked,
  studentAwardable: student_awardable,
  predictedEarnedBadge: predicted_earned_badge
    ? apiDeserializer(predicted_earned_badge)
    : null,
  earnedBadges: earned_badges.map(apiDeserializer),
  isUnlockable: is_unlockable,
  isLocked: is_locked,
  unlockConditions: unlock_conditions,
  unlockKeys: unlock_keys,
  unlockedConditions: unlocked_conditions,
});

const deserializeChallenge = ({
  id,
  accepts_submissions,
  challenge_grade,
  course_id,
  created_at,
  description,
  due_at,
  full_points,
  has_score_level,
  is_due_in_future,
  name,
  open_at,
  predicted_earned_challenge,
  score_level_options,
  visible,
}) => ({
  id,
  acceptsSubmissions: accepts_submissions,
  challengeGrade:
    challenge_grade !== null
      ? apiDeserializer(challenge_grade)
      : challenge_grade,
  courseId: course_id,
  createdAt: created_at,
  description,
  dueAt: due_at,
  fullPoints: full_points,
  hasScoreLevel: has_score_level,
  isDueInFuture: is_due_in_future,
  name,
  openAt: open_at,
  predictedEarnedChallenge:
    predicted_earned_challenge !== null
      ? apiDeserializer(predicted_earned_challenge)
      : predicted_earned_challenge,
  scoreLevelOptions: score_level_options,
  visible,
});

export const getAssignmentTypes = async (courseId) => {
  const url = coursePointsPlannerAPIUrls.assignmentTypeList.stringify({
    courseId,
  });
  const { data } = await apiClient.get(url);
  return data.map(deserializeAssignmentType);
};

export const getBadges = async (courseId) => {
  const url = coursePointsPlannerAPIUrls.badgeList.stringify({ courseId });
  const { data } = await apiClient.get(url);
  return data.map(deserializeBadge);
};

export const getChallenges = async (courseId) => {
  const url = coursePointsPlannerAPIUrls.challengeList.stringify({ courseId });
  const { data } = await apiClient.get(url);
  return data.map(deserializeChallenge);
};

export const createPredictedEarnedBadge = async (courseId, predictionData) => {
  const url = coursePointsPlannerAPIUrls.predictedEarnedBadgeCreate.stringify({
    courseId,
  });
  const serializedPrediction = apiSerializer(predictionData);
  const { data } = await apiClient.post(url, serializedPrediction);
  const { instance, status } = data;
  return {
    status,
    instance: apiDeserializer(instance),
  };
};

export const patchPredictedEarnedBadge = async (courseId, predictionData) => {
  const url = coursePointsPlannerAPIUrls.predictedEarnedBadgeDetail.stringify({
    courseId,
    predictedEarnedBadgeId: predictionData.id,
  });
  const serializedPrediction = apiSerializer(predictionData);
  const { data } = await apiClient.patch(url, serializedPrediction);
  const { instance, status } = data;
  return {
    status,
    instance: apiDeserializer(instance),
  };
};

export const createPredictedEarnedChallenge = async (
  courseId,
  predictionData,
) => {
  const url =
    coursePointsPlannerAPIUrls.predictedEarnedChallengeCreate.stringify({
      courseId,
    });
  const serializedPrediction = apiSerializer(predictionData);
  const { data } = await apiClient.post(url, serializedPrediction);
  const { instance, status } = data;
  return {
    status,
    instance: apiDeserializer(instance),
  };
};

export const patchPredictedEarnedChallenge = async (
  courseId,
  predictionData,
) => {
  const url =
    coursePointsPlannerAPIUrls.predictedEarnedChallengeDetail.stringify({
      courseId,
      predictedEarnedChallengeId: predictionData.id,
    });
  const serializedPrediction = apiSerializer(predictionData);
  const { data } = await apiClient.patch(url, serializedPrediction);
  const { instance, status } = data;
  return {
    status,
    instance: apiDeserializer(instance),
  };
};

export const createPredictedEarnedGrade = async (courseId, predictionData) => {
  const url = coursePointsPlannerAPIUrls.predictedEarnedGradeCreate.stringify({
    courseId,
  });
  const serializedPrediction = apiSerializer(predictionData);
  const { data } = await apiClient.post(url, serializedPrediction);
  const { instance, status } = data;
  return {
    status,
    instance: apiDeserializer(instance),
  };
};

export const patchPredictedEarnedGrade = async (courseId, predictionData) => {
  const url = coursePointsPlannerAPIUrls.predictedEarnedGradeDetail.stringify({
    courseId,
    predictedEarnedGradeId: predictionData.id,
  });
  const serializedPrediction = apiSerializer(predictionData);
  const { data } = await apiClient.patch(url, serializedPrediction);
  const { instance, status } = data;
  return {
    status,
    instance: apiDeserializer(instance),
  };
};

export const createAssignmentTypeWeight = async (
  courseId,
  assignmentTypeWeightData,
) => {
  const url = coursePointsPlannerAPIUrls.assignmentTypeWeightCreate.stringify({
    courseId,
  });
  const serializedAssignmentTypeWeight = apiSerializer(
    assignmentTypeWeightData,
  );
  const { data } = await apiClient.post(url, serializedAssignmentTypeWeight);
  const { instance, status } = data;
  return {
    status,
    instance: apiDeserializer(instance),
  };
};

export const patchAssignmentTypeWeight = async (
  courseId,
  assignmentTypeWeightData,
) => {
  const url = coursePointsPlannerAPIUrls.assignmentTypeWeightDetail.stringify({
    courseId,
    assignmentTypeWeightId: assignmentTypeWeightData.id,
  });
  const serializedAssignmentTypeWeight = apiSerializer(
    assignmentTypeWeightData,
  );
  const { data } = await apiClient.patch(url, serializedAssignmentTypeWeight);
  const { instance, status } = data;
  return {
    status,
    instance: apiDeserializer(instance),
  };
};
