import { ref } from "vue";
import { defineStore } from "pinia";
import { updateNotificationRequest } from "@/courses/services/NotificationService.js";

export const useNotificationStore = defineStore("notifications", () => {
  const notifications = ref([]);

  async function updateNotification(notificationId, courseId, data) {
    const response = await updateNotificationRequest(
      notificationId,
      courseId,
      data,
    );
    mutateNotification(notificationId, response);
  }

  function addNotification(notification) {
    notifications.value.push(notification);
  }
  function removeNotification(notification) {
    notifications.value = notifications.value.filter(
      (existingNotification) => notification.id !== existingNotification.id,
    );
  }
  function mutateNotification(notificationId, data) {
    notifications.value = notifications.value.map((notification) => {
      const updatedNotif = { ...notification, ...data };
      return Number(notificationId) === Number(notification.id)
        ? updatedNotif
        : notification;
    });
    return notifications.value;
  }
  function getNotificationById(notificationId) {
    return notifications.value.find(
      (notification) => Number(notification.id) === Number(notificationId),
    );
  }
  function getNotificationByUserId(userId) {
    return notifications.value.find(
      (notification) => Number(notification.userId) === Number(userId),
    );
  }
  function getNotificationByCourseId(courseId) {
    return notifications.value.find(
      (notification) => Number(notification.courseId) === Number(courseId),
    );
  }
  return {
    notifications,
    updateNotification,
    addNotification,
    mutateNotification,
    removeNotification,
    getNotificationById,
    getNotificationByUserId,
    getNotificationByCourseId,
  };
});
