<template>
  <flat-pickr v-model="dateValue" :config="flatpickrDateTimeConfig" />
</template>

<script setup>
import { ref, watch } from "vue";
const props = defineProps({
  context: {
    type: Object,
    required: true,
  },
});

const dateValue = ref(props.context.value || "");
watch(dateValue, () => {
  props.context.node.input(dateValue.value);
});

let flatpickrDateTimeConfig = {
  allowInput: true,
  altInput: true, // Tue, May 09, 2023 at 11:59 PM
  altFormat: "D, M d, Y at h:i K", //
  dateFormat: "Z", // ISO Date format 2017-03-04T01:23:43.000Z
  enableTime: true,
  minuteIncrement: 1,
  static: true,
};
</script>
