import axios from "axios";
import _mapKeys from "lodash/mapKeys";
import _camelCase from "lodash/camelCase";
import _snakeCase from "lodash/snakeCase";

export const csrftoken = document.head.querySelector("[name='csrf-token']")
  .attributes.content.value;

axios.defaults.headers.common["X-CSRF-Token"] = csrftoken;

export const apiClient = axios.create();

export const formRequestHeader = {
  Accept: "application/json",
  "X-CSRF-Token": csrftoken,
  "X-Requested-With": "XMLHttpRequest",
};
export const mediaHeader = {
  Accept: "*/*",
  "X-CSRF-Token": csrftoken,
  "X-Requested-With": "XMLHttpRequest",
};

export const requestHeader = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "X-CSRF-Token": csrftoken,
  "X-Requested-With": "XMLHttpRequest",
};

export function apiDeserializer(dataObj) {
  return _mapKeys(dataObj, (value, key) => _camelCase(key));
}
export function apiSerializer(dataObj) {
  return _mapKeys(dataObj, (value, key) => _snakeCase(key));
}
