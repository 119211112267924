<template>
  <div v-click-outside="closeDropdowns">
    <button
      class="gc-header__button gc-header__button-header-user"
      :class="{ open: activeUsername }"
      @click="toggleUsername"
      :aria-expanded="activeUsername"
    >
      <img
        v-if="user.avatarUrl"
        class="gc-header__image"
        height="20"
        :src="user.avatarUrl"
        alt=""
      />
      {{ user.firstName + " " + user.lastName }}
    </button>
    <focus-trap
      v-model:active="activeUsername"
      @deactivate="closeDropdowns"
      :initial-focus="handleInitialFocus"
    >
      <div class="gc-header__dropdown-menu" :class="usernameClass">
        <ul class="gc-header__account-menu">
          <li
            v-if="courseId"
            class="gc-header__account-menu-item"
            ref="accountLink"
          >
            <a
              class="gc-header__dropdown-link"
              :href="accountUrl"
              :tabindex="tabindex"
              :aria-hidden="!activeUsername"
              data-target="account-menu-link"
            >
              My Account
            </a>
          </li>
          <li class="gc-header__account-menu-item" ref="logoutLink">
            <a
              class="gc-header__dropdown-link"
              :href="logout"
              :tabindex="tabindex"
              :aria-hidden="!activeUsername"
              data-target="account-menu-link"
            >
              Logout
            </a>
          </li>
        </ul>
      </div>
    </focus-trap>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { editUserProfileUrl, logoutUrl } from "@/courses/urls.js";

export default {
  name: "AccountMenu",
  data() {
    return {
      activeUsername: false,
    };
  },
  computed: {
    ...mapState("navigation", ["courseId"]),
    ...mapState(["user"]),
    usernameClass() {
      return this.activeUsername ? "is-open" : "is-closed";
    },
    logout() {
      return logoutUrl.stringify();
    },
    accountUrl() {
      return editUserProfileUrl.stringify();
    },
    tabindex() {
      return this.activeUsername ? 0 : -1;
    },
  },
  methods: {
    toggleUsername() {
      this.activeUsername = !this.activeUsername;
    },
    closeDropdowns() {
      this.activeUsername = false;
    },
    handleInitialFocus() {
      let firstOption = this.$refs.logoutLink;
      if (this.courseId) {
        firstOption = this.$refs.accountLink;
      }
      firstOption.firstElementChild.focus();
    },
  },
};
</script>
