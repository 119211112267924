<template>
  <nav>
    <ul class="sidenav__list--student">
      <li
        v-for="navItem in navItemList"
        :key="navItem.text"
        :class="{ active: navItem.active }"
        class="sidenav__item"
      >
        <template v-if="navItem.active">
          {{ navItem.text }}
        </template>
        <template v-else>
          <a :href="navItem.url" class="sidenav__link">
            {{ navItem.text }}
          </a>
        </template>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "SideNavStaff",
  computed: {
    ...mapState("navigation", ["courseCreationProgress", "navOpenDefaults"]),
    ...mapGetters("navigation", ["courseStudentNav", "courseObserverNav"]),
    ...mapGetters(["isStudent", "isObserver"]),
    navItemList() {
      if (this.isStudent) {
        return this.courseStudentNav.filter((item) => item.show);
      } else if (this.isObserver) {
        return this.courseObserverNav.filter((item) => item.show);
      }
      return [];
    },
  },
};
</script>
