import { apiClient } from "@/courses/services/GenericService.js";
import { apiClient as apiClientV2 } from "./ApiClientV2";
import {
  courseCopyUrl,
  courseAdminOverviewAPIUrl,
  adminOverviewSearchUsersUrl,
} from "@/courses/urls.js";

export const importCourse = async (formData) => {
  const url = courseCopyUrl.stringify();
  const { status } = await apiClient.post(url, formData);
  return status;
};

export const searchCourses = async ({ courseId, params }) => {
  const url = courseAdminOverviewAPIUrl.stringify({ courseId });
  const { data, status } = await apiClientV2.get(url, { params: params });
  return { data, status };
};

export const searchUsers = async (params) => {
  const url = adminOverviewSearchUsersUrl.stringify();
  const { data } = await apiClientV2.get(url, { params: params });
  return data;
};
