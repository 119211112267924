import { getDashboardBadges } from "@/courses/services/BadgeService.js";
import {
  getAnalyticsPoints,
  getWeeklyStats,
  getAssignmentsByStatus,
  getEvents,
  getGradeSchemeProgress,
  getLearningObjectives,
  getMessage,
  getWeights,
} from "@/courses/services/DashboardService.js";
import * as d3 from "d3";

let boxColorInner = "#8ED0FF"; //$color-blue-5
let boxColorStroke = "#2e70be"; //$blue-medium_1
let barColorGreen = "#42e29d"; // $teal_bright
let barColorBlue = "#6eccf6"; // $blue

function getBoxAndWhiskerData(data) {
  /// Compute summary statistics used for the box-and-whiskers
  let scores = data.course_scores;
  let sortedData = scores.sort(d3.ascending);

  let q1 = d3.quantile(sortedData, 0.25);
  let median = d3.quantile(sortedData, 0.5);
  let q3 = d3.quantile(sortedData, 0.75);
  let interQuantileRange = q3 - q1;
  let outlierMin = q1 - 1.5 * interQuantileRange;
  let outlierMax = q1 + 1.5 * interQuantileRange;
  let whiskerMin = Math.max(d3.min(sortedData), outlierMin);
  let whiskerMax = Math.min(d3.max(sortedData), outlierMax);

  let boxWhiskerData = [whiskerMin, q1, median, q3, whiskerMax];
  return { whiskerMin, whiskerMax, boxWhiskerData };
}

export const courseDashboardModule = {
  namespaced: true,
  state: {
    impersonating: false,
    analyticsPoints: {
      userScore: null,
      pointsPlanned: null,
      averageScore: null,
      classLowest: null,
      classHighest: null,
      userPlanned: null,
      userNeeds: null,
      classSumStats: [],
    },
    weeklyStats: {
      points_this_week: null,
      badges_this_week: null,
      grades_this_week: null,
    },
    assignmentsGraded: [],
    gradedCount: 0,
    assignmentsPlanned: [],
    setPlannedCount: 0,
    assignmentsSubmitted: 0,
    badgesEarned: [],
    badgesPredicted: [],
    events: [
      {
        startDate: "timestamp",
        endDate: "timestamp",
        headline: "name",
        text: "text with html tags",
        description: "plain text description",
        asset: { media: null },
      },
    ],
    gradeSchemeProgress: {
      currentElement: null,
      firstElement: null,
      nextElement: null,
      nextElementConditions: [],
      pointsToNextLevel: null,
      previousElement: null,
      progressPercent: null,
      scoreForCourse: null,
    },
    learningObjectives: [],
    message: null,
    weights: [
      { id: 9, name: "Placeholder", student_weightable: false, weight: 0 },
    ],
    optionsBarchartDefault: {
      chart: {
        type: "bar",
        animations: { enabled: false },
        toolbar: { show: false },
        stacked: true,
      },
      colors: [barColorGreen, barColorBlue],
      dataLabels: { enabled: false },
      fill: { opacity: 1 },
      grid: { xaxis: { lines: { show: true } } },
      legend: {
        onItemClick: { toggleDataSeries: false },
        onItemHover: { highlightDataSeries: false },
      },
      plotOptions: { bar: { horizontal: true, barHeight: "40%" } },
      states: {
        normal: { filter: { type: "none", value: 0 } },
        hover: { filter: { type: "none", value: 0 } },
        active: { filter: { type: "none", value: 0 } },
      },
      stroke: { width: 2, colors: ["#0e0e51", "#0e0e51"], linecap: "square" },
      tooltip: { enabled: false },
      yaxis: { labels: { show: false } },
    },
    optionsBoxplotDefault: {
      chart: {
        type: "boxPlot",
        animations: { enabled: false },
        toolbar: { show: false },
      },
      grid: { xaxis: { lines: { show: true } } },
      plotOptions: {
        boxPlot: { colors: { upper: boxColorInner, lower: boxColorInner } },
        bar: { horizontal: true, barHeight: "40%" },
        xaxis: { labels: { show: false } },
      },
      states: {
        normal: { filter: { type: "none", value: 0 } },
        hover: { filter: { type: "none", value: 0 } },
        active: { filter: { type: "none", value: 0 } },
      },
      stroke: { width: 2, colors: [boxColorStroke] },
      tooltip: { enabled: false },
    },
  },
  actions: {
    async getDashboardBadges({ commit }, courseId) {
      const data = await getDashboardBadges(courseId);
      let earned = data.filter((badge) => badge.earned == true);
      let predicted = data.filter((badge) => badge.predicted == true);
      commit("setBadgesEarned", earned);
      commit("setBadgesPredicted", predicted);
    },
    async getAnalyticsPoints({ commit }, courseId) {
      const data = await getAnalyticsPoints(courseId);

      let allData = {
        userScore: data.user_score,
        pointsPlanned: data.planned_score,
        averageScore: data.average_score,
        classLowest: data.low_score,
        classHighest: data.high_score,
        ...getBoxAndWhiskerData(data),
      };
      commit("setAnalyticsPoints", allData);
    },
    async getWeeklyStats({ commit }, courseId) {
      const data = await getWeeklyStats(courseId);
      commit("setWeeklyStats", data);
    },
    async getAssignmentsByStatus({ commit }, courseId) {
      const data = await getAssignmentsByStatus(courseId);
      // Set Graded
      commit("setAssignmentsGraded", data.graded);
      commit("setGradedCount", data.gradedCount);

      // Set Planned
      commit("setAssignmentsPlanned", data.planned);
      commit("setPlannedCount", data.plannedCount);

      // Set Submitted (just a count)
      commit("setAssignmentsSubmitted", data.submitted);
    },
    async getEvents({ commit }, courseId) {
      let data = await getEvents(courseId);
      commit("setEvents", data);
    },
    async getGradeSchemeProgress({ commit }, courseId) {
      let data = await getGradeSchemeProgress(courseId);
      commit("setGradeSchemeProgress", data);
    },
    async getLearningObjectives({ commit }, courseId) {
      const data = await getLearningObjectives(courseId);
      commit("setLearningObjectives", data);
    },
    async getMessage({ commit }, courseId) {
      const data = await getMessage(courseId);
      commit("setMessage", data);
    },
    async getWeights({ commit }, courseId) {
      const data = await getWeights(courseId);
      commit("setWeights", data);
    },
  },
  mutations: {
    setImpersonating(state, data) {
      state.impersonating = data;
    },
    setAnalyticsPoints(state, data) {
      state.analyticsPoints = data;
    },
    setWeeklyStats(state, data) {
      state.weeklyStats = data;
    },
    setBadgesEarned(state, data) {
      state.badgesEarned = data;
    },
    setBadgesPredicted(state, data) {
      state.badgesPredicted = data;
    },
    setAssignmentsGraded(state, data) {
      state.assignmentsGraded = data;
    },
    setGradedCount(state, data) {
      state.gradedCount = data;
    },
    setAssignmentsPlanned(state, data) {
      state.assignmentsPlanned = data;
    },
    setPlannedCount(state, data) {
      state.plannedCount = data;
    },
    setAssignmentsSubmitted(state, data) {
      state.assignmentsSubmitted = data;
    },
    setEvents(state, data) {
      state.events = data;
    },
    setGradeSchemeProgress(state, data) {
      state.gradeSchemeProgress = data;
    },
    setLearningObjectives(state, data) {
      state.learningObjectives = data;
    },
    setMessage(state, data) {
      state.message = data;
    },
    setWeights(state, data) {
      state.weights = data;
    },
  },
};
