import {
  getAssignmentTypes,
  getBadges,
  getChallenges,
  createAssignmentTypeWeight,
  patchAssignmentTypeWeight,
  createPredictedEarnedBadge,
  patchPredictedEarnedBadge,
  createPredictedEarnedChallenge,
  patchPredictedEarnedChallenge,
  createPredictedEarnedGrade,
  patchPredictedEarnedGrade,
} from "@/courses/services/PointsPlannerService.js";

function sumWeight(array, attrBase) {
  return array.reduce((sum, obj) => {
    let maxPoints = obj.maxPoints;
    let predictedPoints = obj[attrBase] * obj.weight.weight;
    return maxPoints && predictedPoints > maxPoints
      ? sum + maxPoints
      : sum + predictedPoints;
  }, 0);
}
export const pointsPlannerModule = {
  namespaced: true,
  state: {
    assignmentTypes: [],
    badges: [],
    challenges: [],
    cardClasses: {
      "focus-article": false,
      "status-completed": false,
      "status-no-points": false,
    },
    previewing: {
      type: null,
      id: null,
    },
  },
  getters: {
    assignments(state) {
      return state.assignmentTypes
        .map((assignmentType) => assignmentType.assignments)
        .flat();
    },
    lockedAssignments(state, getters) {
      return getters.assignments.filter((assignment) => assignment.isLocked);
    },
    earnedBadges(state) {
      return state.badges.filter((badge) => badge.earnedBadges.length > 0);
    },
    gradedChallenges(state) {
      return state.challenges.filter(
        (challenge) => challenge.challengeGrade !== null,
      );
    },
    ungradedPredictedChallenges(state) {
      return state.challenges.filter(
        (challenge) =>
          challenge.challengeGrade === null &&
          challenge.predictedEarnedChallenge !== null,
      );
    },
    grandTotalPointsPredicted(state, getters) {
      let total =
        getters.totalAssignmentPointsPredicted -
        getters.grandTotalLockedPointsPredicted +
        getters.totalBadgePointsPredicted +
        getters.totalChallengePointsPredicted;
      return total;
    },
    grandTotalChallengePoints(state) {
      return state.challenges.reduce((sum, challenge) => {
        return challenge.challengeGrade
          ? sum + challenge.challengeGrade.finalPoints
          : challenge.predictedEarnedChallenge
            ? sum + challenge.predictedEarnedChallenge.predictedPoints
            : sum;
      }, 0);
    },
    grandTotalLockedPointsPredicted(state, getters) {
      let assignmentPoints = getters.lockedAssignments.reduce(
        (sum, assignment) => {
          const noGrade = assignment.grade === null;
          const hasPredictedEarnedGrade =
            assignment.predictedEarnedGrade !== null;
          const predictionExcluded = assignment.predictionExcluded;
          return noGrade && hasPredictedEarnedGrade && !predictionExcluded
            ? sum +
                assignment.predictedEarnedGrade.predictedPoints *
                  assignment.weight
            : sum;
        },
        0,
      );

      let badgePoints = state.badges
        .filter((badge) => badge.isLocked)
        .reduce((sum, badge) => {
          const earnedCount = badge.earnedBadges.length;
          const predictedCount = badge.predictedEarnedBadge
            ? badge.predictedEarnedBadge.predictedTimesEarned
            : 0;
          const count =
            earnedCount >= predictedCount ? earnedCount : predictedCount;
          return sum + count * badge.fullPoints;
        }, 0);
      return assignmentPoints + badgePoints;
    },
    grandTotalPointsEarned(state, getters) {
      return (
        getters.totalAssignmentPointsEarned +
        getters.totalBadgePointsEarned +
        getters.totalChallengePointsEarned
      );
    },
    totalAssignmentPointsPredicted(state) {
      return sumWeight(state.assignmentTypes, "totalPointsPredicted");
    },
    totalAssignmentPointsEarned(state) {
      return sumWeight(state.assignmentTypes, "totalPointsEarned");
    },
    totalBadgePointsPredicted(state) {
      return state.badges.reduce((sum, badge) => {
        const earnedCount = badge.earnedBadges.length;
        const predictedCount = badge.predictedEarnedBadge
          ? badge.predictedEarnedBadge.predictedTimesEarned
          : 0;
        const count =
          earnedCount >= predictedCount ? earnedCount : predictedCount;
        return sum + count * badge.fullPoints;
      }, 0);
    },
    totalBadgePointsEarned(state, getters) {
      return getters.earnedBadges.reduce((sum, badge) => {
        const earnedCount = badge.earnedBadges.length;
        return sum + earnedCount * badge.fullPoints;
      }, 0);
    },
    totalChallengePointsPredicted(state, getters) {
      return (
        getters.ungradedPredictedChallenges.reduce((sum, challenge) => {
          const predictedPoints =
            challenge.predictedEarnedChallenge.predictedPoints;
          return sum + predictedPoints;
        }, 0) + getters.totalChallengePointsEarned
      );
    },
    totalChallengePointsEarned(state, getters) {
      return getters.gradedChallenges.reduce((sum, challenge) => {
        const finalPoints = challenge.challengeGrade.finalPoints;
        return sum + finalPoints;
      }, 0);
    },
    previewingAssignment(state) {
      return state.previewing.type === "assignment";
    },
    previewingBadge(state) {
      return state.previewing.type === "badge";
    },
    previewingChallenge(state) {
      return state.previewing.type === "challenge";
    },
    previewItem(state, getters) {
      if (getters.previewingAssignment) {
        return getters.assignments.find(
          (assignment) => assignment.id === state.previewing.id,
        );
      } else if (getters.previewingBadge) {
        return state.badges.find((badge) => badge.id === state.previewing.id);
      } else if (getters.previewingChallenge) {
        return state.challenges.find(
          (challenge) => challenge.id === state.previewing.id,
        );
      }
      return undefined;
    },
    previewItemTerm(state, getters, rootState) {
      switch (state.previewing.type) {
        case "assignment":
          return rootState.course.currentCourse.term_for_assignment;
        case "challenge":
          return rootState.course.currentCourse.term_for_challenge;
        case "badge":
          return rootState.course.currentCourse.term_for_badge;
        default:
          return "item";
      }
    },
    weightsOpen(state, getters, rootState) {
      let now = new Date();
      let closeDateTime = rootState["course"].currentCourse.weights_close_at;
      // if close date is null/false, weights always open
      // return false if close time is before now
      return closeDateTime ? new Date(closeDateTime) > now : true;
    },
    usedWeights(state) {
      return state.assignmentTypes.reduce((sum, assignmentType) => {
        return assignmentType.studentWeightable
          ? sum + assignmentType.weight.weight
          : sum;
      }, 0);
    },
    unusedWeights(state, getters, rootState) {
      const totalWeights = rootState["course"].currentCourse.total_weights;
      return totalWeights - getters.usedWeights;
    },
    numAssignmentTypesWeighted(state) {
      return state.assignmentTypes.filter(
        (at) => at.studentWeightable && at.weight.weight > 0,
      ).length;
    },
  },
  mutations: {
    setAssignmentTypes(state, assignmentTypes) {
      state.assignmentTypes = assignmentTypes;
    },
    setBadges(state, badges) {
      state.badges = badges;
    },
    setChallenges(state, challenges) {
      state.challenges = challenges;
    },
    setCardClasses(state, classObject) {
      state.cardClasses = classObject;
    },
    setPredictedEarnedBadge(state, predictedEarnedBadge) {
      const badge = state.badges.find(
        (badge) => badge.id === predictedEarnedBadge.badgeId,
      );
      badge.predictedEarnedBadge = predictedEarnedBadge;
    },
    setPredictedEarnedChallenge(state, predictedEarnedChallenge) {
      const challenge = state.challenges.find(
        (challenge) => challenge.id === predictedEarnedChallenge.challengeId,
      );
      challenge.predictedEarnedChallenge = predictedEarnedChallenge;
    },
    setPredictedEarnedGrade(state, predictedEarnedGrade) {
      const assignmentType = state.assignmentTypes.find(
        (assignmentType) =>
          assignmentType.id === predictedEarnedGrade.assignmentTypeId,
      );
      const assignment = assignmentType.assignments.find(
        (assignment) => assignment.id === predictedEarnedGrade.assignmentId,
      );
      const previouslyPredicted = assignment.predictedEarnedGrade !== null;
      if (!assignment.passFail) {
        if (previouslyPredicted) {
          assignmentType.totalPointsPredicted -=
            assignment.predictedEarnedGrade.predictedPoints;
        }
        assignmentType.totalPointsPredicted +=
          predictedEarnedGrade.predictedPoints;
      }

      assignment.predictedEarnedGrade = predictedEarnedGrade;
    },
    setPreviewing(state, { type, id }) {
      state.previewing.type = type;
      state.previewing.id = id;
    },
    setAssignmentTypeWeight(state, assignmentTypeWeight) {
      const assignmentType = state.assignmentTypes.find(
        (assignmentType) =>
          assignmentType.id === assignmentTypeWeight.assignmentTypeId,
      );
      assignmentType.weight = assignmentTypeWeight;
      assignmentType.assignments.forEach((assignment) => {
        assignment.weight = assignmentTypeWeight.weight;
      });
    },
  },
  actions: {
    async getAssignmentTypes({ commit }, courseId) {
      const assignmentTypes = await getAssignmentTypes(courseId);
      commit("setAssignmentTypes", assignmentTypes);
    },
    async getBadges({ commit }, courseId) {
      const badges = await getBadges(courseId);
      commit(
        "setBadges",
        badges.sort((a, b) =>
          a.position > b.position ? 1 : b.position > a.position ? -1 : 0,
        ),
      );
    },
    async getChallenges({ commit }, courseId) {
      const challenges = await getChallenges(courseId);
      commit("setChallenges", challenges);
    },
    async createPredictedEarnedBadge({ commit }, { courseId, predictionData }) {
      const response = await createPredictedEarnedBadge(
        courseId,
        predictionData,
      );
      if (response.status === 201) {
        commit("setPredictedEarnedBadge", response.instance);
      }
    },
    async patchPredictedEarnedBadge({ commit }, { courseId, predictionData }) {
      const response = await patchPredictedEarnedBadge(
        courseId,
        predictionData,
      );
      if (response.status === 200) {
        commit("setPredictedEarnedBadge", response.instance);
      }
    },
    async createPredictedEarnedChallenge(
      { commit },
      { courseId, predictionData },
    ) {
      const response = await createPredictedEarnedChallenge(
        courseId,
        predictionData,
      );
      if (response.status === 201) {
        commit("setPredictedEarnedChallenge", response.instance);
      }
    },
    async patchPredictedEarnedChallenge(
      { commit },
      { courseId, predictionData },
    ) {
      const response = await patchPredictedEarnedChallenge(
        courseId,
        predictionData,
      );
      if (response.status === 200) {
        commit("setPredictedEarnedChallenge", response.instance);
      }
    },
    async createPredictedEarnedGrade({ commit }, { courseId, predictionData }) {
      const response = await createPredictedEarnedGrade(
        courseId,
        predictionData,
      );
      if (response.status === 201) {
        commit("setPredictedEarnedGrade", response.instance);
      }
    },
    async patchPredictedEarnedGrade({ commit }, { courseId, predictionData }) {
      const response = await patchPredictedEarnedGrade(
        courseId,
        predictionData,
      );
      if (response.status === 200) {
        commit("setPredictedEarnedGrade", response.instance);
      }
    },
    async createAssignmentTypeWeight({ commit }, { courseId, data }) {
      const response = await createAssignmentTypeWeight(courseId, data);
      if (response.status === 201) {
        commit("setAssignmentTypeWeight", response.instance);
      }
    },
    async patchAssignmentTypeWeight({ commit }, { courseId, data }) {
      const response = await patchAssignmentTypeWeight(courseId, data);
      if (response.status === 200) {
        commit("setAssignmentTypeWeight", response.instance);
      }
    },
  },
};
