import { apiClient } from "@/courses/services/GenericService.js";
import {
  apiClient as apiClientV2,
  formDataAPIClient,
} from "@/courses/services/ApiClientV2.js";
import {
  ASSIGNMENTS_API_V2_URLS,
  assignmentTypeListAPIUrl,
  assignmentProviderCSVImportUrl,
  assignmentProviderUploadUrl,
  ASSIGNMENT_TYPES_API_V2_URLS,
} from "@/courses/urls.js";

export const getAssignmentsRequest = (courseId) => {
  return apiClient.get(ASSIGNMENTS_API_V2_URLS.index.stringify({ courseId }));
};
export const getAssignmentRequest = (courseId, assignmentId) => {
  return apiClientV2.get(
    ASSIGNMENTS_API_V2_URLS.resource.stringify({
      courseId,
      assignmentId,
    }),
  );
};

export const getAssignmentOptions = async (courseId) => {
  const url = ASSIGNMENTS_API_V2_URLS.options.stringify({
    courseId: courseId,
  });
  const { data } = await apiClientV2.get(url);
  return data;
};

export const getAssignmentTypes = async () => {
  const url = assignmentTypeListAPIUrl.stringify();
  const { data } = await apiClient.get(url);
  return data.data
    .map((row) => row.attributes)
    .sort((a, b) => a.name.localeCompare(b.name));
};

export const getAssignmentTypeOptions = async (courseId) => {
  const url = ASSIGNMENT_TYPES_API_V2_URLS.options.stringify({
    courseId: courseId,
  });
  const { data } = await apiClientV2.get(url);
  return data;
};

export const uploadAssignmentCSV = async (courseId, formData) => {
  const url = assignmentProviderUploadUrl.stringify({
    courseId,
    providerName: "csv",
  });
  const { data } = await formDataAPIClient.post(url, formData);
  return data.data.map((row) => row.attributes);
};

export const importAssignmentsFromCSV = async (courseId, assignments) => {
  const url = assignmentProviderCSVImportUrl.stringify({
    courseId,
    providerName: "csv",
  });
  const { data } = await apiClientV2.post(url, {
    assignments: { assignment_attributes: assignments },
  });
  return data;
};
