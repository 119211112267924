import _uniqueId from "lodash/uniqueId";
import {
  requestUnlockConditionsList,
  requestUnlockConditionCreate,
  requestUnlockConditionUpdate,
  requestUnlockConditionDelete,
} from "@/courses/services/UnlocksService.js";
import { conditionType } from "@/courses/utilities/constants.js";
import {
  getUnlockableTypeKey,
  parseUnlocksErrors,
} from "@/courses/utilities/unlocks.js";
import { newBaseUrl, editBaseUrl } from "@/courses/urls.js";
import {
  getAssignmentOptions,
  getAssignmentTypeOptions,
} from "@/courses/services/AssignmentService.js";
import { getBadgeOptions } from "@/courses/services/BadgeService.js";
import { getLearningObjectiveOptions } from "@/courses/services/LearningObjectivesService.js";

/*
Grab the edit page we're currently on - used for determining the list API endpoint and the type of unlock in form copy
  Endpoint examples:
    /courses/1/grade_scheme_elements/11/edit
    /courses/1/badges/1/edit
    /courses/1/assignments/127/edit
 */
const unlocksBaseUrl =
  editBaseUrl.match(location.pathname) || newBaseUrl.match(location.pathname);

export const unlocksModule = {
  namespaced: true,
  state: {
    unlocks: [],
    errors: [],
    assignmentOptions: [],
    assignmentTypeOptions: [],
    badgeOptions: [],
    learningObjectiveOptions: [],
  },
  mutations: {
    setUnlocks(state, unlocks) {
      state.unlocks = unlocks;
    },
    addUnlockCondition(state, { unlockableType, unlockableId }) {
      // Convert URL condition type string to condition type key used in constants
      // unlockabe_type is required for unlocks, but DB doesn't enforce this.
      const conditionTypeKey = getUnlockableTypeKey(unlockableType);
      state.unlocks = [
        ...state.unlocks,
        {
          id: _uniqueId("tmp-"),
          attributes: {
            condition_type: "Assignment",
            unlockable_id: unlockableId,
            unlockable_type: conditionType[conditionTypeKey],
            condition_date: null,
          },
          touched: true,
        },
      ];
    },
    updateUnlockConditionOption(state, data) {
      state.unlocks = state.unlocks.map((unlock) =>
        unlock.id === data.id ? data : unlock,
      );
    },
    removeUnlockCondition(state, unlockId) {
      state.unlocks = [
        ...state.unlocks.filter((unlock) => unlock.id != unlockId),
      ];
    },
    clearUnlockCondition(state, unlockId) {
      const keysToClear = [
        "condition_id",
        "min_condition_value",
        "condition_state",
        "condition_date",
      ];
      state.unlocks = state.unlocks.map((unlock) => {
        if (unlock.id === unlockId) {
          let newAttributes = {};
          Object.keys(unlock.attributes).map((attrKey) => {
            if (keysToClear.includes(attrKey)) {
              newAttributes[attrKey] = null;
            } else {
              newAttributes[attrKey] = unlock.attributes[attrKey];
            }
          });
          unlock.attributes = newAttributes;
        }
        return unlock;
      });
    },
    validateConditions(state, currentCourse) {
      if (state.unlocks.length < 1) {
        return;
      }
      const errors = [];
      let unlockIdx = 1;
      state.unlocks.forEach((conditionObj) => {
        const condition = conditionObj.attributes;
        let error = "";
        switch (condition.condition_type) {
          case "Assignment":
            if (!condition.condition_id || !condition.condition_state) {
              error = `Unlock condition ${unlockIdx} invalid: ${currentCourse.termFor.assignment} name and state achieved must be specified`;
            } else if (condition.condition_state == "Grade Earned") {
              if (
                !(condition.min_condition_value > 0) &&
                (!condition.max_condition_value ||
                  condition.max_condition_value == 0)
              ) {
                error = `Unlock condition ${unlockIdx} invalid: minimum or maximum earned points must be specified`;
              } else if (
                condition.max_condition_value &&
                Number(condition.max_condition_value) <
                  Number(condition.min_condition_value)
              ) {
                error = `Unlock condition ${unlockIdx} invalid: maximum earned points must be greater than the minimum`;
              }
            }
            break;

          case "AssignmentType":
            if (!condition.condition_id || !condition.condition_state) {
              error = `Unlock condition ${unlockIdx} invalid: ${currentCourse.termFor.assignment_type} name and state achieved must be specified`;
            } else if (!(condition.min_condition_value > 0)) {
              error = `Unlock condition ${unlockIdx} invalid: minimum or maximum number of times earned must be specified`;
            }
            break;

          case "Badge":
            if (
              !condition.condition_id ||
              !condition.min_condition_value ||
              !(condition.min_condition_value > 0)
            ) {
              error = `Unlock condition ${unlockIdx} invalid: ${currentCourse.termFor.badge} name and number of times earned must be specified`;
            }
            break;

          case "LearningObjective":
            if (!condition.condition_id || !condition.condition_state) {
              error = `Unlock condition ${unlockIdx} invalid: ${currentCourse.termFor.learning_objective} name and state achieved must be specified`;
            }
            break;

          case "Course":
            if (
              !condition.min_condition_value ||
              !(condition.min_condition_value > 0)
            ) {
              error = `Unlock condition ${unlockIdx} invalid: minimum earned points must be specified`;
            }
            break;
        }
        if (error.length > 0) {
          this.formValid = false;
          errors.push(error);
        }
        unlockIdx += 1;
      });
      state.errors = errors;
    },
    setErrors(state, errors) {
      state.errors = errors;
    },
    clearErrors(state) {
      state.errors = [];
    },
    addAssignments(state, options) {
      state.assignmentOptions = options;
    },
    addAssignmentTypes(state, options) {
      state.assignmentTypeOptions = options;
    },
    addBadges(state, options) {
      state.badgeOptions = options;
    },
    addLearningObjectives(state, options) {
      state.learningObjectiveOptions = options;
    },
  },
  actions: {
    async getUnlockConditions({ commit }, { unlockableId, unlockableType }) {
      const response = await requestUnlockConditionsList(
        unlockableId,
        unlockableType,
      );
      commit("setUnlocks", response.data);
    },
    async createOrUpdateUnlockConditions({ commit, state }) {
      const unlocks = state.unlocks;
      const promises = unlocks.map((unlockParams) => {
        if (unlockParams.id.includes("tmp-")) {
          return requestUnlockConditionCreate(unlockParams);
        } else if (unlockParams.id > 0) {
          return requestUnlockConditionUpdate(unlockParams);
        } else {
          console.log("No ID provided for unlock");
        }
      });
      // Default to current state in case any responeses fail - validation *should* catch errors client-side before hitting API
      try {
        const results = await Promise.all(promises);
        commit(
          "setUnlocks",
          results.map((response) => response.data),
        );
      } catch (err) {
        const errorResponse = err.response.data.errors;
        commit("setErrors", parseUnlocksErrors(errorResponse));
        commit("setUnlocks", unlocks);
        throw new Error(err);
      }
    },
    async updateUnlockCondition({ commit }, data) {
      const response = await requestUnlockConditionUpdate(data);
      commit("setUnlocks", response.data);
    },
    async deleteUnlockCondition({ commit }, conditionId) {
      if (!conditionId.startsWith("tmp-")) {
        await requestUnlockConditionDelete(conditionId);
      }
      commit("removeUnlockCondition", conditionId);
    },
    async getAssignments({ commit }, courseId) {
      const { data } = await getAssignmentOptions(courseId);
      commit("addAssignments", data);
    },
    getAssignmentTypes: async function ({ commit }, courseId) {
      const { data } = await getAssignmentTypeOptions(courseId);
      commit("addAssignmentTypes", data);
    },
    getBadges: async function ({ commit }, courseId) {
      const { data } = await getBadgeOptions({ courseId });
      commit("addBadges", data);
    },
    getLearningObjectives: async function ({ commit }, courseId) {
      const { data } = await getLearningObjectiveOptions(courseId);
      commit("addLearningObjectives", data);
    },
  },
  getters: {
    lastUpdated: (state) => {
      // This is a little weird, but the backend doesn't support updating a single GradeSchemeElement attribute without side-effects
      // so this is primarily used as the source for updated date on the Grade Scheme
      const sortedUnlocks = state.unlocks.sort(
        (a, b) =>
          new Date(a.attributes.updated_at) - new Date(b.attributes.updated_at),
      );
      if (sortedUnlocks.length > 0) {
        return sortedUnlocks[0].attributes.updated_at;
      }
    },
    unlockableType: () => (unlocksBaseUrl ? unlocksBaseUrl.editPageType : null),
    unlockableId: () => (unlocksBaseUrl ? unlocksBaseUrl.itemId : null),
  },
};
