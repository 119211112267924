import humps from "humps";

export function convertFilterQuery(filterQuery) {
  const snakeCasedFilterQuery = humps.decamelizeKeys(filterQuery);
  return Object.keys(snakeCasedFilterQuery).reduce((params, key) => {
    const filter = { [`q[${key}]`]: snakeCasedFilterQuery[key] };
    return { ...params, ...filter };
  }, {});
}

export const getQueryParams = ({ userId, courseId, options }) => {
  let filterParams = { userId: userId };
  if (courseId) filterParams.courseId = courseId;
  if (options) filterParams = { ...filterParams, ...options };
  return convertFilterQuery(filterParams);
};

export const getUrlParams = () =>
  new URLSearchParams(window.location.toString().split("?")[1]);
